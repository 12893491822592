import request from '@/utils/request'

export function createHome (data) {
  return request({
    url: '/api-manager/app/home',
    method: 'post',
    data
  })
}

export function fetchInfoHome () {
  return request({
    url: '/api-manager/app/home',
    method: 'get'
  })
}

export function createTerms (data) {
  return request({
    url: '/api-manager/app/terms',
    method: 'post',
    data
  })
}

export function getTerms () {
  return request({
    url: '/api-manager/app/terms',
    method: 'get'
  })
}

export function createChapultepec (data) {
  return request({
    url: '/api-manager/app/chapultepec',
    method: 'post',
    data
  })
}

export function fetchInfoChapultepec () {
  return request({
    url: '/api-manager/app/chapultepec',
    method: 'get'
  })
}

export function createProject (data) {
  return request({
    url: '/api-manager/app/projects',
    method: 'post',
    data
  })
}

export function getProject () {
  return request({
    url: '/api-manager/app/projects',
    method: 'get'
  })
}

export function createAcknowledgment (data) {
  return request({
    url: '/api-manager/app/acknowledgment',
    method: 'post',
    data
  })
}

export function getAcknowledgment () {
  return request({
    url: '/api-manager/app/acknowledgment',
    method: 'get'
  })
}

export function createAchievements (data) {
  return request({
    url: '/api-manager/app/achievements',
    method: 'post',
    data
  })
}

export function getAchievements () {
  return request({
    url: '/api-manager/app/achievements',
    method: 'get'
  })
}
