<template>
    <div class="app-container">
    <el-row :gutter="4" type="flex" justify="center">
      <el-col :span="20">
        <el-card class="box-card">
          <div slot="header" class="clearfix" justify="center" align="center">
            <strong><span>Avisos de privacidad y términos de condiciones </span></strong>
          </div>
          <div class="text item" id="terms">
            <el-row justify="right">
              <el-col align="right">
                <el-button
                @click.prevent="callPreviewService"
                type="danger"> Preview</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <CKEditor :setContent="editorData" :key="editorData" @getContent="getUpdatedContent"></CKEditor>
              </el-col>
            </el-row>
            <el-row type="flex" justify="center">
              <el-col :xs="8" :md="6" align="middle">
                <el-button
                  @click.prevent="handlerCreate"
                  type="primary"
                  icon="el-icon-upload2">
                  Actualizar
                </el-button>
              </el-col>
              <el-col :xs="8" :md="6" align="middle">
                <el-button
                  @click.prevent="handlerCancel"
                  type="danger">
                  Cancelar
                </el-button>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    </div>
  </template>

<script>
import CKEditor from '@/components/CKEditor/CKEditor.vue'
import { getTerms, createTerms } from '@/api/app.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi

export default {
  name: 'terms',
  components: {
    CKEditor
  },
  data () {
    return {
      host: urlServer,
      preview: '',
      loading: false,
      contentEditor: '',
      editorData: ''
    }
  },
  mounted () {
    this.handleGetTerms()
  },
  methods: {
    callPreviewService () {
      window.open(this.preview, '_blank')
    },
    handlerCancel () {
      this.$router.back()
    },
    async handlerCreate () {
      try {
        this.editorData = this.contentEditor
        const formData = new FormData()
        formData.append('terms', this.editorData)
        const response = await createTerms(formData)
        if (response.success) {
          console.log(response)
          this.$message({
            showClose: true,
            message: '¡Se cargó correctamente!',
            type: 'success'
          })
        } else {
          console.log(response)
        }
      } catch (err) {
        err.response.data.messages.forEach(message => {
          this.$message.error(`${message}`)
        })
      }
    },
    async handleGetTerms () {
      this.loading = true
      await getTerms()
        .then((response) => {
          var data = response.data
          if (data.length !== 0) {
            this.editorData = response.data.terms
            this.preview = response.data.preview
          }
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    getUpdatedContent (content) {
      this.contentEditor = content
    }
  }
}
</script>

  <style scoped>
  .label-form-validate-wrong {
    color: #F56C6C;
  }

  .size-form-item > .el-form-item {
    margin: 0;
  }
  .size-form-item > .el-form--label-top .el-form-item__label {
    padding: 0;
    margin-bottom: 0;
  }
  .my-autocomplete {
    width: 500px;
  }
  </style>
